.scrolling-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }
  
  .scrolling-message {
    white-space: nowrap;
    position: absolute;
    color: greenyellow;
    font-family: 'Arcade';
  }
  @font-face {
    font-family: 'Arcade';
    src: url('../assets/fonts/ARCADE.TTF') format('truetype');
  }
  