body {
    background: #000;
}

.landing {
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.logo {
    height: 2%;
    top: 10%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.frame {
    top: 35%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 31vh;
    width: auto;
}

.gif {
    width: auto;
    height: 22vh;
    top: 40%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}